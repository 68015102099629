<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      style="margin-top: 25px; margin-left: 60px"
    >
      <el-form-item
        :label="$t('System.S6000ConfigPage.IPAddress') + '：'"
        prop="ip"
      >
        <el-input
          v-model.trim="ruleForm.ip"
          size="small"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('System.S6000ConfigPage.port') + '：'"
        prop="port"
      >
        <el-input
          minlength="1"
          maxlength="5"
          size="small"
          style="width: 250px"
          v-model.trim="ruleForm.port"
          onkeyup="value=value.replace(/[^\d]/g,'')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('System.S6000ConfigPage.inforType') + '：'"
        prop="content"
      >
        <el-checkbox-group v-model="ruleForm.content">
          <p>
            <el-checkbox  value="0" label="0">{{
              $t("System.S6000ConfigPage.heartbeatMessage")
            }}</el-checkbox>
          </p>
          <p>
            <el-checkbox  value="1" label="1">{{
              $t("System.S6000ConfigPage.statistic")
            }}</el-checkbox>
          </p>
          <p>
            <el-checkbox  value="2" label="2">{{
              $t("System.S6000ConfigPage.AlarmInfor")
            }}</el-checkbox>
          </p>
          <p>
            <el-checkbox  value="3" label="3">{{
              $t("System.S6000ConfigPage.ManagementLog")
            }}</el-checkbox>
          </p>
          <p>
            <el-checkbox  value="4" label="4">{{
              $t("System.S6000ConfigPage.TerminalLogs")
            }}</el-checkbox>
          </p>
          <p>
            <el-checkbox  value="5" label="5">{{
              $t("System.S6000ConfigPage.AlarmLog")
            }}</el-checkbox>
          </p>
          <p>
            <el-checkbox  value="6" label="6">{{
              $t("System.S6000ConfigPage.GatewayAccessLog")
            }}</el-checkbox>
          </p>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item
        :label="$t('System.S6000ConfigPage.Enable') + '：'"
        prop="enable"
      >
        <el-checkbox
          
          value="0"
          v-model="ruleForm.enable"
          true-label="1"
          false-label="0"
        ></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="small"
          @click="submitForm('ruleForm')"
          >{{ $t("public.Save") }}</el-button
        >
      </el-form-item>
    </el-form>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import dialogInfo from "@/components/promptMessage/index";
import { log } from "console";
export default {
  components: {
    dialogInfo,
  },
  props: {
    addArray: {
      type: Object,
      default: () => {},
    },
    addArrayId: {
      type: String,
      default: "",
    },
  },
  data() {
    let self = this;
    function validateIP(rule, value, callback) {
      var IPRegex =
        /^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/;
      let valdata = value.split("\n");
      valdata.forEach((item) => {
        if (!IPRegex.test(item)) {
          callback(new Error(this.$t("System.S6000ConfigPage.IPReg")));
          return;
        }
      });
      callback();
    }

    return {
      zoom: 15,
      flag: 1,
      flagTest: false,
      placeSearch: null,
      visibaelFlag: false,
      flagStatus: false,
      input: "",
      titleInfo: "",
      IDS: [""],
      ruleForm: {
        ip: "",
        port: "",
        content: [""],
        enable: 0,
      },
      rules: {
        ip: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("System.S6000ConfigPage.IPVoid"),
          },
          {
            validator: (rule, value, callback) => {
              value.split("\n").forEach((item) => {
                if (
                  !/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/.test(
                    item
                  )
                ) {
                  callback(new Error(this.$t("System.S6000ConfigPage.IPReg")));
                  return;
                }
              });
              callback();
            },
            trigger: "blur",
          },
        ],
        port: [
          {
            required: true,
            message: this.$t("System.S6000ConfigPage.portReg"),
            trigger: "blur",
          },
          {
            min: 1,
            max: 5,
            message: this.$t("System.S6000ConfigPage.portVoid"),
            trigger: "blur",
          },
        ],
        content: [
          { required: true, message: " ", trigger: "blur" },
          // { validator: validateInput1, trigger: ["blur", "change"] },
        ],
      },
      label: {
        content: this.$t("System.S6000ConfigPage.CustomTitle"),
        offset: [10, 12],
      },
      events: {
        click(e) {
          console.log(e);
        },
      },
    };
  },
  created() {
    this.IDS = this.addArrayId;
    if (this.addArray && this.addArray.id) {
      this.ruleForm.ip = this.addArray.ip + "";
      this.ruleForm.port = this.addArray.port + "";
      //res.content.sendTemp="1,2,3"
      const sendStrList = [];
      const sendTempList = this.addArray.content.split(`,`);
      // for (let i = 0; i < sendTempList.length; i++) {
      //   switch (sendTempList[i]) {
      //     case `1`:
      //       sendStrList.push(`1`);
      //       break;
      //     case `2`:
      //       sendStrList.push(`2`);
      //       break;
      //     case `3`:
      //       sendStrList.push(`3`);
      //       break;
      //     case `4`:
      //       sendStrList.push(`4`);
      //       break;
      //     case `5`:
      //       sendStrList.push(`5`);
      //       break;
      //     case `6`:
      //       sendStrList.push(`6`);
      //       break;
      //     case `7`:
      //       sendStrList.push(`7`);
      //       break;
      //     case `8`:
      //       sendStrList.push(`8`);
      //       break;
      //   }
      // }
      // this.addArray.content = sendStrList.length > 0 ? sendStrList.join(`,`) : ``;
      // this.ruleForm.content = sendStrList;
      this.ruleForm.content = this.addArray.content.split(`,`);
      this.ruleForm.enable = this.addArray.enable + "";
    }
  },
  mounted() {},
  methods: {
    async save() {
      if (this.IDS) {
        let params = {
          ids: [this.IDS],
          ip: this.ruleForm.ip,
          content: this.ruleForm.content.toString(),
          port: this.ruleForm.port,
          enable: this.ruleForm.enable.toString(),
        };
        console.log(params, "---保存编辑");
        const res = await this.$axios.post(
          "/httpServe/s6000/save",
          params,
          true
        );

        if (res.data == -1) {
          this.$message({
            message: this.$t("System.S6000ConfigPage.CertificateLimit"),
            type: "error",
            offset: 100,
          });
          this.ruleForm.content = [""];
          return;
        } else {
          this.$message({
            message: this.$t("public.SuccessfullySaved"),
            type: "success",
            offset: 100,
          });
          this.ruleForm.content = [""];
          this.flag = 1;
          this.$emit("sunline", this.flag);
        }
      } else {
        this.ruleForm.content = this.ruleForm.content.toString();
        console.log(this.ruleForm.content, "this.ruleForm.content");
        var contentStr = this.ruleForm.content.substr(1);
        console.log(contentStr, "contentStr");
        let params = {
          ip: this.ruleForm.ip,
          content: contentStr,
          port: this.ruleForm.port,
          enable: this.ruleForm.enable.toString(),
        };
        console.log(params);
        const res = await this.$axios.post(
          "/httpServe/s6000/save",
          params,
          true
        );
        console.log(res, "新增");
        if (res.data == -1) {
          // 端口不能相同
          this.$message({
            message: this.$t("System.S6000ConfigPage.CertificateLimit"),
            type: "error",
            offset: 100,
          });
          this.ruleForm.content = [""];
          return false;
        } else {
          this.$message({
            message: this.$t("public.SuccessfullySaved"),
            type: "success",
            offset: 100,
          });
          this.ruleForm.content = [""];
          this.flag = 1;
          this.$emit("sunline", this.flag);
        }
      }
    },
    submitForm(formName) {
      let contentFlag = false;
      console.log(this.ruleForm.content, "this.ruleForm.content");
      this.$refs[formName].validate((valid) => {
        if (
          this.ruleForm.content == [] ||
          this.ruleForm.content == "" ||
          this.ruleForm.content == [""]
        ) {
          contentFlag = false;
          this.$message({
            message: this.$t("System.S6000ConfigPage.SelectInforType"),
            type: "error",
            offset: 100,
          });
          return false;
        } else {
          contentFlag = true;
        }
        if (valid && contentFlag === true) {
          this.save();
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleClose() {
      this.visibaelFlag = false;
    },
    determine() {
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 14px !important;
}
::v-deep .amap-maptypecontrol {
  // display: none;
}
::v-deep .amap-icon img {
  width: 10px;
  height: 10px;
}
.search-box {
  margin-top: 6px;
  width: 400px;
}
.search-box input {
  padding: 0 15px;
  width: 100%;
  height: 32px;
  line-height: 32px;
  color: #606266;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.search-box input:focus {
  border-color: #409eff;
  outline: 0;
}
.search-box input::-webkit-input-placeholder {
  color: #c0c4cc;
}
.tip-box {
  width: 400px;
  max-height: 400px;
  position: absolute;
  top: 72px;
  // z-index: 10000;
  overflow-y: auto;
  background-color: #fff;
}
</style>
<style>
.amap-ui-poi-picker-sugg,
.amap_lib_placeSearch {
  border: 1px solid #eee;
  border-radius: 4px;
}
.amap-box {
  width: 606px;
  height: 406px;
}
.box-conent {
  position: relative;
}
.inp {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>